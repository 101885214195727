import { createSelector } from 'reselect';
import { STATE_LOADING } from '../redux.constants';
import { STEPS } from './mockup-manager.constants';
const rootSelector = state => state.mockupManager;
export const currentStepSelector = createSelector(rootSelector, ({
  isUserReadBeforeApproving,
  isNotecardReview,
  isLogoUploadCompleted,
  isRequestingChanges
}) => {
  if (!isUserReadBeforeApproving) {
    return STEPS.BEFORE_APPROVING_MOCKUP;
  }
  if (!isRequestingChanges || !isNotecardReview) {
    return STEPS.APPROVE_MOCKUP;
  }
  if (!isLogoUploadCompleted) {
    return STEPS.NOTECARD_LOGO_AND_COLOR;
  }
  return STEPS.NOTECARD_TEXT_CONTENT;
});
export const mockupSelector = createSelector(rootSelector, mockupManager => mockupManager.mockup);
export const isNotecardReviewSelector = createSelector(rootSelector, mockupManager => mockupManager.isNotecardReview);
export const isMockupAvailableSelector = createSelector(mockupSelector, mockup => {
  return Boolean(mockup);
});
export const itemIdSelector = createSelector(rootSelector, mockupManager => mockupManager.itemId);
export const orderIdSelector = createSelector(rootSelector, mockupManager => mockupManager.orderId);
export const requestChangesStateSelector = createSelector(rootSelector, mockupManager => mockupManager.requestChangesState);
export const stateSelector = createSelector(rootSelector, mockupManager => mockupManager.state);
export const isDisabledSelector = createSelector(rootSelector, mockupManager => mockupManager.isDisabled);
export const isRequestingChangesSelector = createSelector(rootSelector, mockupManager => mockupManager.isRequestingChanges);
export const isPdfSelector = createSelector(rootSelector, mockupManager => {
  const fileNameParts = mockupManager.mockup.fileName.split('.');
  return fileNameParts[fileNameParts.length - 1] === 'pdf';
});
export const changeRequestTextSelector = createSelector(rootSelector, mockupManager => mockupManager.mockup.changeRequest);
export const isNotecardChangesSubmitting = createSelector(requestChangesStateSelector, state => state === STATE_LOADING);