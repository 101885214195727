import { createSelector } from 'reselect';
export const featureTogglesRootSelector = state => state.featureToggles;
export const featureTogglesSelector = createSelector(featureTogglesRootSelector, ({
  toggles
}) => toggles);
export const faqSwagSpacePageOnInventoryFeatureEnabledSelector = createSelector(featureTogglesSelector, ({
  faqSwagSpacePageOnInventory
}) => faqSwagSpacePageOnInventory);
export const faqCustomInkPageOnInventoryFeatureEnabledSelector = createSelector(featureTogglesSelector, ({
  faqCustomInkPageOnInventory
}) => faqCustomInkPageOnInventory);
export const specializedFulfillmentFeatureEnabledSelector = createSelector(featureTogglesSelector, ({
  specializedFulfillment
}) => specializedFulfillment);
export const autoReplenishInventoryFeatureEnabledSelector = createSelector(featureTogglesSelector, ({
  autoReplenishInventory
}) => autoReplenishInventory);
export const isSwagBundlesFlowFeatureEnabledSelector = createSelector(featureTogglesSelector, ({
  swagBundlesFlow
}) => swagBundlesFlow);
export const isSwagBundlesInventoryTransferSelector = createSelector(featureTogglesSelector, ({
  swagBundlesInventoryTransfer
}) => swagBundlesInventoryTransfer);
export const isSwagBundlesCreateGiveawaySelector = createSelector(featureTogglesSelector, ({
  swagBundlesCreateGiveaway
}) => swagBundlesCreateGiveaway);
export const isSwagBundlesOrderMoreSelector = createSelector(featureTogglesSelector, ({
  swagBundlesOrderMore
}) => swagBundlesOrderMore);