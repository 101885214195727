import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { CHANGE_ACTIVE_SUBCATEGORY_ID, CHANGE_APPLIED_CATEGORY_FILTER, CHANGE_CATEGORY } from '../product-listing/product-listing.actions';
import { SEARCH_REQUEST as PRODUCTS_SEARCH_REQUEST } from '../search-products/search-products.actions';
import { getUrlParamsObject, getInitialFilter, getInitialAttributeMap, getInitialSorting } from '../product-listing/product-listing.utils';
import { CHANGE_IS_SHOW_MENU_STATE } from '../pages/pages.actions';
import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL, SEARCH_REQUEST, SEARCH_SUCCESS, GET_PRODUCTS_STARTED, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL, SORTING_CHANGED, RESET_PRODUCTS, CHANGE_CHECKBOX_FILTER, GET_FILTER_LOOKUPS_SUCCESS, REMOVE_FILTER_ITEM, APPLY_FILTER, RESET_FILTER, RESET_SORTING, CHANGE_QUERY_STR, CHANGE_SELECTED_AND_APPLIED_FILTERS, CHANGE_SELECTED_PRODUCT, SET_FILTER_VISIBILITY, SEARCH_ERROR, SELECT_ALL, CHANGE_PRICE_RANGE, CHANGE_RADIO_FILTER, OPEN_FILTER_IN_SEARCH_POPUP, CLOSE_FILTER_IN_SEARCH_POPUP, CHANGE_IS_FOR_INVENTORY, CHANGE_IS_FOR_BOX, CHANGE_IS_FOR_SUPER_SPEED } from './search-results.actions';
const initialUrl = getUrlParamsObject();
const initialFilter = getInitialFilter(initialUrl);
const initialState = {
  msg: undefined,
  snackbarIsOpen: undefined,
  categories: [],
  categoriesMap: {},
  facets: {},
  attributesMap: getInitialAttributeMap(initialUrl),
  currentCategoryName: '',
  query: initialUrl.get('query') || '',
  products: {
    matchByName: [],
    matchByCategory: [],
    matchByTag: []
  },
  selectedProduct: '',
  sorting: getInitialSorting({
    initialUrl
  }),
  pagination: {
    page: 0,
    lastPage: 1
  },
  total: 0,
  activeSubcategoryId: '',
  filterLookups: {
    production: {
      filterData: []
    },
    categoryId: {
      filterData: []
    }
  },
  selectedFilter: initialFilter,
  appliedFilter: initialFilter,
  isLoading: false,
  isVisible: Object.keys(initialFilter).length > 0,
  isFilterPopupOpen: true,
  error: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      {
        const {
          categories,
          categoriesMap
        } = action;
        return _objectSpread({}, state, {
          categories,
          categoriesMap
        });
      }
    case GET_CATEGORIES_FAIL:
      {
        const {
          error
        } = action;
        return _objectSpread({}, state, {
          msg: error.message,
          error
        });
      }
    case SEARCH_ERROR:
      {
        const {
          error
        } = action;
        return _objectSpread({}, state, {
          error,
          total: 0,
          isLoading: false,
          products: {
            matchByName: [],
            matchByCategory: [],
            matchByTag: []
          }
        });
      }
    case PRODUCTS_SEARCH_REQUEST:
      {
        const {
          query
        } = action.payload;
        return _objectSpread({}, state, {
          query
        });
      }
    case GET_PRODUCTS_STARTED:
    case SEARCH_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true,
          error: null
        });
      }
    case SEARCH_SUCCESS:
      {
        const {
          matchByName = [],
          matchByCategory = [],
          matchByTag = [],
          page,
          lastPage,
          total,
          query,
          facets
        } = action.payload;
        return _objectSpread({}, state, {
          total,
          query,
          facets,
          pagination: {
            page,
            lastPage
          },
          products: {
            matchByName,
            matchByTag,
            matchByCategory
          },
          isLoading: false,
          error: null
        });
      }
    case GET_PRODUCTS_SUCCESS:
      {
        const {
          matchByName = [],
          matchByCategory = [],
          matchByTag = [],
          page = 1,
          lastPage,
          total,
          facets
        } = action.payload;
        const {
          products
        } = state;
        const byName = _uniqBy(products.matchByName.concat(matchByName), '_id');
        const byCategory = _uniqBy(products.matchByCategory.concat(matchByCategory), '_id');
        const byTag = _uniqBy(products.matchByTag.concat(matchByTag), '_id');
        const newProducts = {
          matchByName: byName,
          matchByCategory: byCategory,
          matchByTag: byTag
        };
        return _objectSpread({}, state, {
          total,
          facets,
          pagination: {
            page,
            lastPage
          },
          products: newProducts,
          isLoading: false,
          error: null
        });
      }
    case GET_PRODUCTS_FAIL:
      {
        const {
          error
        } = action;
        return _objectSpread({}, state, {
          snackbarIsOpen: true,
          isLoading: false,
          msg: JSON.stringify(error.message),
          error,
          total: 0
        });
      }
    case SORTING_CHANGED:
      {
        return _objectSpread({}, state, {
          sorting: action.sorting
        });
      }
    case CHANGE_SELECTED_AND_APPLIED_FILTERS:
      {
        const {
          appliedFilter,
          selectedFilter,
          filterLookups,
          productId = ''
        } = action.payload;
        return _objectSpread({}, state, {
          appliedFilter,
          selectedFilter,
          filterLookups,
          selectedProduct: productId
        });
      }
    case RESET_PRODUCTS:
      {
        return _objectSpread({}, state, {
          products: {
            matchByName: [],
            matchByCategory: [],
            matchByTag: []
          },
          pagination: {
            page: 0,
            lastPage: 1
          }
        });
      }
    case CHANGE_CATEGORY:
      {
        const {
          category
        } = action.payload;
        return _objectSpread({}, state, {
          currentCategoryName: category.categoryName
        });
      }
    case CHANGE_ACTIVE_SUBCATEGORY_ID:
      {
        const {
          activeSubcategoryId
        } = action.payload;
        return _objectSpread({}, state, {
          activeSubcategoryId
        });
      }
    case CHANGE_APPLIED_CATEGORY_FILTER:
      {
        const categoryIds = action.payload;
        return _objectSpread({}, state, {
          selectedFilter: _objectSpread({}, state.selectedFilter, {
            categoryId: [...categoryIds.categoryIds]
          }),
          appliedFilter: _objectSpread({}, state.appliedFilter, {
            categoryId: [...categoryIds.categoryIds]
          })
        });
      }
    case SELECT_ALL:
      {
        const {
          filterId,
          values,
          isAttributes
        } = action.payload;
        const nextSelectedFilter = _objectSpread({}, state.selectedFilter, {
          [filterId]: values
        });
        if (!nextSelectedFilter[filterId].length) {
          delete nextSelectedFilter[filterId];
        }
        const attributesMap = isAttributes ? _objectSpread({}, state.attributesMap, {
          [filterId]: filterId
        }) : state.attributesMap;
        return _objectSpread({}, state, {
          selectedFilter: nextSelectedFilter,
          attributesMap
        });
      }
    case CHANGE_RADIO_FILTER:
      {
        const {
          filterId,
          filterPropertyValue
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        const index = updatedFilter[filterId] && updatedFilter[filterId].indexOf(filterPropertyValue);
        if (index !== -1 && index !== undefined) {
          delete updatedFilter[filterId];
          return _objectSpread({}, state, {
            selectedFilter: updatedFilter
          });
        }
        updatedFilter[filterId] = [filterPropertyValue];
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_CHECKBOX_FILTER:
      {
        const {
          filterId,
          filterPropertyValue,
          isAttributes
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        const index = updatedFilter[filterId] && updatedFilter[filterId].indexOf(filterPropertyValue);
        const attributesMap = isAttributes ? _objectSpread({}, state.attributesMap, {
          [filterId]: filterId
        }) : state.attributesMap;
        if (index !== -1 && index !== undefined) {
          updatedFilter[filterId].splice(index, 1);
          if (!updatedFilter[filterId].length) {
            delete updatedFilter[filterId];
            return _objectSpread({}, state, {
              selectedFilter: updatedFilter,
              attributesMap
            });
          }
          return _objectSpread({}, state, {
            attributesMap,
            selectedFilter: _objectSpread({}, updatedFilter, {
              [filterId]: updatedFilter[filterId]
            })
          });
        }
        if (!updatedFilter[filterId]) {
          updatedFilter[filterId] = [];
        }
        updatedFilter[filterId].push(filterPropertyValue);
        return _objectSpread({}, state, {
          attributesMap,
          selectedFilter: updatedFilter
        });
      }
    case GET_FILTER_LOOKUPS_SUCCESS:
      {
        const {
          filterLookups
        } = state;
        return _objectSpread({}, state, {
          filterLookups
        });
      }
    case APPLY_FILTER:
      {
        const appliedFilter = _cloneDeep(state.selectedFilter);
        if (!appliedFilter.production) {
          delete appliedFilter.production;
        }
        const {
            attributes
          } = appliedFilter,
          rest = _objectWithoutProperties(appliedFilter, ["attributes"]);
        return _objectSpread({}, state, {
          appliedFilter: _objectSpread({}, rest, attributes || {})
        });
      }
    case REMOVE_FILTER_ITEM:
      {
        const {
          filterId,
          filterIndex
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.appliedFilter);
        if (!!filterId && filterIndex !== undefined) {
          updatedFilter[filterId].splice(filterIndex, 1);
        }
        if (!updatedFilter[filterId].length || typeof updatedFilter[filterId] === 'string' || filterId === FilterIds.price || filterId === FilterIds.isInventory || filterId === FilterIds.isBox || filterId === FilterIds.isSuperSpeed) {
          delete updatedFilter[filterId];
        }
        return _objectSpread({}, state, {
          selectedFilter: _cloneDeep(updatedFilter),
          appliedFilter: updatedFilter,
          isVisible: Object.values(updatedFilter).length === 0 ? false : state.isVisible
        });
      }
    case RESET_FILTER:
      {
        return _objectSpread({}, state, {
          selectedFilter: {},
          appliedFilter: {}
        });
      }
    case CHANGE_QUERY_STR:
      {
        const {
          query
        } = action.payload;
        return _objectSpread({}, state, {
          query
        });
      }
    case RESET_SORTING:
      {
        return _objectSpread({}, state, {
          sorting: initialState.sorting
        });
      }
    case CHANGE_SELECTED_PRODUCT:
      {
        return _objectSpread({}, state, {
          selectedProduct: action.payload.selectedProduct
        });
      }
    case SET_FILTER_VISIBILITY:
      {
        const {
          isVisible
        } = action.payload;
        return _objectSpread({}, state, {
          isVisible
        });
      }
    case CHANGE_PRICE_RANGE:
      {
        const {
          range
        } = action.payload;
        return _objectSpread({}, state, {
          selectedFilter: _objectSpread({}, state.selectedFilter, {
            price: range
          }),
          appliedFilter: _objectSpread({}, state.selectedFilter, {
            price: range
          })
        });
      }
    case CHANGE_IS_FOR_BOX:
      {
        const {
          isBox
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isBox) {
          updatedFilter.isBox = true;
        } else {
          delete updatedFilter.isBox;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_FOR_INVENTORY:
      {
        const {
          isInventory
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isInventory) {
          updatedFilter.isInventory = true;
        } else {
          delete updatedFilter.isInventory;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_FOR_SUPER_SPEED:
      {
        const {
          isSuperSpeed
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isSuperSpeed) {
          updatedFilter.isSuperSpeed = true;
          delete updatedFilter.productionTime;
        } else {
          delete updatedFilter.isSuperSpeed;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_SHOW_MENU_STATE:
    case CLOSE_FILTER_IN_SEARCH_POPUP:
      {
        return _objectSpread({}, state, {
          isFilterPopupOpen: false
        });
      }
    case OPEN_FILTER_IN_SEARCH_POPUP:
      {
        return _objectSpread({}, state, {
          isFilterPopupOpen: true
        });
      }
    default:
      {
        return state;
      }
  }
}