import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
export const getLogosUniqueColorsCount = logos => {
  const uniqueColors = [];
  return Object.keys(logos).reduce((colorsSum, location) => {
    const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => {
      var _nextLogo$colors$sele, _nextLogo$colors, _nextLogo$colors$sele2;
      const selectedColorsNumber = (_nextLogo$colors$sele = nextLogo === null || nextLogo === void 0 ? void 0 : (_nextLogo$colors = nextLogo.colors) === null || _nextLogo$colors === void 0 ? void 0 : (_nextLogo$colors$sele2 = _nextLogo$colors.selectedColors) === null || _nextLogo$colors$sele2 === void 0 ? void 0 : _nextLogo$colors$sele2.reduce((selectedColorsSum, selectedColor) => {
        if (!uniqueColors.includes(selectedColor.hex)) {
          uniqueColors.push(selectedColor.hex);
          return selectedColorsSum + 1;
        }
        return selectedColorsSum;
      }, 0)) !== null && _nextLogo$colors$sele !== void 0 ? _nextLogo$colors$sele : 0;
      return colorsSumPerLocation + selectedColorsNumber;
    }, 0);
    return number + colorsSum;
  }, 0);
};
export const getLogosUniqueColorsCountInItem = item => {
  return !item || item.prod && isProductFullDesign(item.prod) ? 1 : getLogosUniqueColorsCount(item === null || item === void 0 ? void 0 : item.logos);
};
export const isSomeItemHasSSColorLimitError = items => {
  return items.some(item => {
    var _item$prod, _item$prod$superSpeed;
    const maxPrintColors = item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$superSpeed = _item$prod.superSpeed) === null || _item$prod$superSpeed === void 0 ? void 0 : _item$prod$superSpeed.maxPrintColors;

    // 0 maxPrintColors means infinity max print colors
    if (isSuperSpeedItem(item) && maxPrintColors === 0) {
      return false;
    }
    return isSuperSpeedItem(item) && (maxPrintColors !== null && maxPrintColors !== void 0 ? maxPrintColors : 1) < getLogosUniqueColorsCountInItem(item);
  });
};