import _head from "lodash/head";
import _get from "lodash/get";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getProductOutOfStockThreshold } from 'swag-common/utils/product/product.utils';
import { getProductInStockLevelsByVariantSku } from 'swag-common/utils/product/product.utils';
import getNotificationText from 'swag-common/utils/product/get-notification-text-for-product-quantity.util';
import { spreadQuantities } from 'swag-common/utils/product/product.utils';
import { productViewsSelector as mainProductViewsSelector } from '../product/product.selectors';
import { isAnyLogoUploadedSelector, logosSelector, layersSelector, textsSelector } from '../logo/logo.selectors';
import { getPercentageDifference, prepareItemToShare, MIN_RELATED_AMOUNT } from './product.utils';
import { selectedSplitColorsTooltip, unselectedSplitColorsTooltip } from './product.constants';
const DEFAULT_MINIMUM_COLORS_NUMBER = 1;
const defaultLogos = {
  front: [{
    colors: {
      colorsToPrint: ['White'],
      colorsNumber: DEFAULT_MINIMUM_COLORS_NUMBER
    }
  }],
  back: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  left: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  right: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }]
};
export const cartItemsSelector = state => state.cart.items;
export const parentProductIdSelector = state => state.product.product._id;
export const relatedProductSelector = state => state.relatedProduct;
export const productSelector = state => state.relatedProduct.product;
export const productVariantsSelector = state => state.relatedProduct.product.variants;
export const productLoadedSelector = state => state.relatedProduct.productLoaded;
export const sizeLoadedSelector = state => state.relatedProduct.sizeLoaded;
export const productIdSelector = state => state.relatedProduct.product._id;
export const serviceCodeSelector = state => state.relatedProduct.serviceCode;
export const variantSelector = state => {
  const {
    productLoaded,
    product
  } = state.relatedProduct;
  if (!productLoaded) {
    return null;
  }
  return (product.variants || []).find((item, index) => (item.index || index) === state.relatedProduct.variantIndex);
};
export const isFullDesignProductSelector = state => isProductFullDesign(state.relatedProduct.product);
export const imagesWithoutImprintAreaSelector = state => state.relatedProduct.product.imagesWithoutImprintArea;
export const variantIndexSelector = state => state.relatedProduct.variantIndex;
export const sizeIndexSelector = state => state.relatedProduct.sizeIndex;
export const methodIndexSelector = state => state.relatedProduct.methodIndex;
export const embellishmentMethodsSelector = state => state.relatedProduct.product.embellishmentMethods;
export const viewTypeSelector = state => state.relatedProduct.view;
export const designNotesSelector = state => state.relatedProduct.designNotes;
export const maxPrintColorsSelector = state => state.relatedProduct.product.maxPrintColors;
export const printingProcessDescriptionSelector = state => state.relatedProduct.product.printingProcessDescription;
export const discountSelector = state => state.relatedProduct.discount;
export const selectedProductionTimeSelector = state => state.relatedProduct.selectedProductionTime;
export const sizeSelector = state => state.relatedProduct.size;
export const minimumQuantitySelector = state => state.relatedProduct.product.minQuant;
export const itemIdSelector = state => state.relatedProduct.itemId;
export const orderModeSelector = state => state.relatedProduct.mode;
export const popupModeSelector = state => state.relatedProduct.popupMode;
export const selectedVariantsSelector = state => state.relatedProduct.selectedVariants.sort((previousVariantIndex, variantIndex) => previousVariantIndex > variantIndex ? 1 : -1);
export const splitOrderModeSelector = state => state.relatedProduct.splitOrderMode;
export const variantDependentPriceSelector = state => state.relatedProduct.product.variantDependentPrice;
export const sizeDependentImagesSelector = state => state.relatedProduct.product.sizeDependentImages;
export const showMoreImageModeSelector = state => state.relatedProduct.showMoreImageMode;
export const blankSamplePriceSelector = state => state.relatedProduct.product.samplePrice;
export const printedSamplePriceSelector = state => state.relatedProduct.product.printedSamplePrice;
export const additionalPrintLocationsSelector = state => state.relatedProduct.product.additionalPrintLocations ? state.relatedProduct.product.additionalPrintLocations.map(location => location) : [];
export const quantitiesSelector = state => state.relatedProduct.quantities;
export const quantitiesReadySelector = state => state.relatedProduct.quantitiesReady;
export const showColorSectionSelector = state => state.relatedProduct.product.variants.length > 0;
export const showEmbellishmentMethodSectionSelector = state => state.relatedProduct.product.embellishmentMethods && state.relatedProduct.product.embellishmentMethods.length > 1;
export const designAttachmentsSelector = state => state.relatedProduct.attachments;
const productInStockLevelsByVariantSkuSelector = createSelector(productSelector, getProductInStockLevelsByVariantSku);
const productIsAlwaysInStockSelector = createSelector(productSelector, product => product.isAlwaysInStock);
export const quantitySelector = createSelector(selectedVariantsSelector, quantitiesSelector, (variants, quantities) => variants.reduce((allVariantsQuantity, variantIndex) => {
  if (!quantities[variantIndex]) {
    return allVariantsQuantity;
  }
  const singleVariantQuantity = quantities[variantIndex].reduce((variantQuantity, q) => q + variantQuantity, 0);
  return allVariantsQuantity + singleVariantQuantity;
}, 0));
export const minimumQuantityErrorSelector = createSelector(minimumQuantitySelector, quantitySelector, (minQuant, totalQuantity) => minQuant > totalQuantity);
export const maximumQuantityErrorSelector = createSelector(quantitySelector, totalQuantity => totalQuantity.toString().length > 6);
export const sizeDependentPriceSelector = createSelector(productSelector, product => product.sizeDependentPrice);
export const productOutOfStockThresholdSelector = createSelector(productSelector, product => getProductOutOfStockThreshold(product));
export const hasSizeSelector = createSelector(productSelector, product => product.hasSize);
export const productSkuSelector = createSelector(productLoadedSelector, variantIndexSelector, productSelector, (isLoaded, variantIndex, product) => isLoaded ? product.variants[variantIndex] && product.variants[variantIndex].productSku || product.num : null);
export const variantImagesSelector = createSelector(productSelector, variantIndexSelector, viewTypeSelector, (product, variantIdx, view) => {
  const images = _get(product, ['variants', variantIdx, 'images'], {});
  return {
    images: Object.values(images).filter(Boolean).map(_head),
    view
  };
});
const selectedVariantsDataSelector = createSelector(productLoadedSelector, selectedVariantsSelector, productVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, productVariants, quantities) => {
  if (!productLoaded || !productVariants.length) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const variant = productVariants.find((item, index) => (item.index || index) === variantIndex);
    const {
      color,
      name,
      productSku,
      isAlwaysInStockVariant,
      sizeSkus
    } = variant || {};
    const variantQuantities = quantities[variantIndex] || [0];
    return {
      variantIndex,
      color,
      name,
      quantities: variantQuantities,
      productSku,
      isAlwaysInStockVariant,
      sizeSkus
    };
  });
});
const orderItemVariantsSelector = createSelector(productLoadedSelector, selectedVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, quantities) => {
  if (!productLoaded) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const variantQuantities = quantities[variantIndex];
    return {
      variantIndex,
      quantities: variantQuantities
    };
  });
});
export const selectedEmbellishmentMethodSelector = createSelector(embellishmentMethodsSelector, methodIndexSelector, showEmbellishmentMethodSectionSelector, (embellishmentMethods, methodIndex, useEmbellishmentMethod) => {
  if (useEmbellishmentMethod) {
    return embellishmentMethods[methodIndex];
  }
  return null;
});
export const orderItemSelector = createSelector([productSelector, productLoadedSelector, productIdSelector, orderItemVariantsSelector, productSkuSelector, sizeIndexSelector, logosSelector, layersSelector, quantitySelector, designNotesSelector, discountSelector, selectedProductionTimeSelector, orderModeSelector, itemIdSelector, serviceCodeSelector, cartItemsSelector, variantDependentPriceSelector, isAnyLogoUploadedSelector, selectedEmbellishmentMethodSelector, designAttachmentsSelector, textsSelector], (product, isProductLoaded, prodId, selectedVariants, itemSku, sizeInd, logos, layers, quantity, designNotes, discount, selectedProductionTime, mode, itemId, serviceCode, cartItems, variantDependentPrice, isDesignUploaded, embellishmentMethod, attachments, texts) => {
  if (!isProductLoaded) {
    return {};
  }
  const itemLogos = Object.keys(layers).reduce((resultingLogos, location) => _objectSpread({}, resultingLogos, {
    [location]: layers[location].map(id => logos[id]).filter(logo => !!logo)
  }), {});
  const colorsAmount = Object.keys(logos).reduce((colorsSum, logoId) => logos[logoId].colors.colorsNumber + colorsSum, 0);
  const orderItem = {
    prodId,
    variants: selectedVariants,
    itemSku,
    sizeInd,
    logos: itemLogos,
    quantity,
    designNotes: designNotes || '',
    attachments,
    discount,
    prodTime: selectedProductionTime,
    texts,
    _id: itemId
  };
  if (embellishmentMethod) {
    orderItem.embellishmentMethod = embellishmentMethod;
  }
  if (variantDependentPrice) {
    orderItem.variantInd = selectedVariants[0].variantIndex;
  }
  orderItem.colors = colorsAmount;
  if (isProductFullDesign(product)) {
    // always count design as 1 color print, unless there is no design uploaded
    orderItem.colors = isDesignUploaded ? DEFAULT_MINIMUM_COLORS_NUMBER : 0;
  }
  orderItem.price = Price.getPrice({
    orderItem,
    product
  });
  if (mode === PRODUCT_BUILDER_MODES.REBUILD_MODE) {
    const itemToRebuild = cartItems.find(item => item._id === itemId);
    const {
      totalCharges,
      isNyTaxFree,
      deliveryTime,
      vendor,
      inHandDate,
      status
    } = itemToRebuild;
    orderItem._id = itemId;
    // tslint:disable-next-line: prefer-object-spread
    _extends(orderItem, {
      serviceCode,
      totalCharges,
      isNyTaxFree,
      deliveryTime,
      vendor,
      inHandDate,
      status
    });
  }
  return orderItem;
});
const isOrderValidSelector = createSelector(maximumQuantityErrorSelector, minimumQuantityErrorSelector, orderItemSelector, (maximumQuantityError, minimumQuantityError, orderItem) => isFinite(orderItem.price) && !maximumQuantityError && !minimumQuantityError);
export const addToCartEnabledSelector = createSelector(isOrderValidSelector, isOrderValid => isOrderValid);
export const getPricesWithQuantity = createSelector(productSelector, productLoadedSelector, orderItemSelector, (product, productLoaded, orderItem) => {
  const {
    printRunQuantities
  } = product;
  const {
    prodTime,
    discount,
    colors,
    logos,
    sizeInd,
    variantInd,
    defaultPrintingMethod
  } = orderItem;

  //duck tape for non-active relatedProduct
  if (!printRunQuantities) {
    return [{
      cost: 1
    }];
  }
  return printRunQuantities.map(quantity => {
    const hasColor = orderItem.colorsNumber;
    const item = {
      quantity,
      discount,
      prodTime,
      variantInd,
      logos: hasColor && logos || defaultLogos,
      colors: colors || DEFAULT_MINIMUM_COLORS_NUMBER,
      sizeInd,
      defaultPrintingMethod
    };
    const cost = Math.round(Price.getPrice({
      orderItem: item,
      product
    }) / quantity);
    return {
      quantity,
      cost
    };
  });
});
const calculatePriceBreaks = prices => {
  const maxPrice = prices[0].cost;
  return prices.map(item => {
    const saving = getPercentageDifference(maxPrice, item.cost);
    return _objectSpread({}, item, {
      saving
    });
  });
};
export const priceBreaksSelector = createSelector(getPricesWithQuantity, prices => {
  const priceBreaks = calculatePriceBreaks(prices);
  const hasMoreThanOneItem = priceBreaks.length > 1;
  return {
    prices: priceBreaks,
    showPriceBreaks: hasMoreThanOneItem
  };
});
export const resetEditedItemSelector = createSelector(orderItemSelector, orderModeSelector, productSelector, (item, mode, product) => {
  const resetedItem = prepareItemToShare(item);
  if (mode === PRODUCT_BUILDER_MODES.REBUILD_MOCKUP_MODE) {
    resetedItem.prod = product;
  }
  return resetedItem;
});
export const cartSelector = state => state.cart;
export const sampleItemSelector = createSelector(productSelector, resetEditedItemSelector, cartSelector, variantIndexSelector, sizeDependentPriceSelector, sizeIndexSelector, sizeLoadedSelector, sizeSelector, hasSizeSelector, blankSamplePriceSelector, (product, orderItem, cart, variantIndex, sizeDependent, sizeIndex, sizeLoaded, size, hasSize) => {
  const {
    _id
  } = product;
  if (!sizeLoaded) {
    return {};
  }
  let quantities;
  if (hasSize) {
    quantities = sizeDependent ? spreadQuantities({
      quantity: 1,
      itemsQuantityToSpread: size.options.length,
      allQuantityTo: sizeIndex,
      sizeOptions: size.options
    }) : spreadQuantities({
      quantity: 1,
      itemsQuantityToSpread: size.options.length,
      sizeOptions: size.options
    });
  } else {
    quantities = [1];
  }
  return _objectSpread({}, orderItem, {
    prodId: _id,
    quantity: 1,
    variants: [{
      variantIndex,
      quantities
    }]
  });
});
export const printedSampleItemSelector = createSelector(sampleItemSelector, printedSamplePriceSelector, (item, price) => _objectSpread({}, item, {
  price
}));
export const blankSampleItemSelector = createSelector(sampleItemSelector, blankSamplePriceSelector, (item, price) => _objectSpread({}, item, {
  price
}));
export const showPriceBreaksSelector = createSelector(priceBreaksSelector, ({
  showPriceBreaks
}) => showPriceBreaks);
export const productInformationSelector = createSelector(productSelector, isFullDesignProductSelector, printedSamplePriceSelector, blankSamplePriceSelector, (product, isFullDesignProduct, printedSamplePrice, blankSamplePrice) => {
  const {
    name,
    descr,
    _id,
    forbiddenForInventory,
    forbiddenForBox
  } = product;
  return {
    name: name.trim().replace(/ {2}/g, ' '),
    descr,
    productId: _id,
    hasBlankSample: !!blankSamplePrice,
    hasPrintedSample: !!printedSamplePrice,
    forbiddenForInventory,
    acceptableForBox: !forbiddenForBox
  };
});
export const specsSelector = state => {
  const {
    product: {
      product: {
        specs,
        imagesForSpec
      }
    }
  } = state;
  return {
    specs,
    images: imagesForSpec
  };
};
export const teeImagePopupSelector = state => {
  const {
    product: {
      product: {
        imagesForSpec
      }
    }
  } = state;
  return {
    images: imagesForSpec
  };
};
export const isToolsPanelShownSelector = state => {
  const {
    product: {
      view
    },
    logo: {
      layers,
      logos,
      texts
    }
  } = state;
  const isDesignUploadMode = isFullDesignProductSelector(state);
  if (isDesignUploadMode) {
    return false;
  }
  if (!layers[view] || !layers[view].length) {
    return false;
  }
  const notOldFormatOfColor = !layers[view].some(id => !texts[id] && !!logos[id].oldFormat);
  const logoWasUploaded = !!layers[view].length;
  return logoWasUploaded && notOldFormatOfColor && !isDesignUploadMode;
};
export const currentLogoSelector = state => {
  const {
    logo: {
      logos,
      selectedLogo
    }
  } = state;
  if (selectedLogo) {
    return _objectSpread({}, logos[selectedLogo], {
      logoId: selectedLogo
    });
  }
};
export const currentLogoColorsSelector = state => {
  const {
    product: {
      view,
      colors
    }
  } = state;
  if (!colors[view]) {
    return colors.front;
  }
  return colors[view].colorsToPrint;
};

// get images from all selected variants.
export const selectedVariantsImagesSelector = createSelector(sizeIndexSelector, viewTypeSelector, variantIndexSelector, productVariantsSelector, productLoadedSelector, sizeDependentImagesSelector, imagesWithoutImprintAreaSelector, isFullDesignProductSelector, (sizeIndex, view, variantIndex, variants, productLoaded, sizeDependentImages, imagesWithoutImprintArea, isFullDesignProduct) => {
  if (!productLoaded) {
    return [];
  }
  if (isFullDesignProduct) {
    return imagesWithoutImprintArea.map(images => _objectSpread({}, images, {
      variantIndex
    }));
  } // return imagesWithoutImprintArea if there are no variants.

  const imageSizeIndex = sizeDependentImages ? sizeIndex : 0;
  const {
    images
  } = mergeImages(variants[variantIndex], imagesWithoutImprintArea, view, imageSizeIndex, variantIndex);
  return images.sort(({
    isImageWithImprintArea
  }) => isImageWithImprintArea ? -1 : 1);
});
export const activeProductImageSelector = createSelector(selectedVariantsImagesSelector, viewTypeSelector, variantIndexSelector, (currentImages, view, variantIndex) => {
  const activeProductImage = currentImages.find(image => {
    const isSameView = view === image.id;
    const isSameVariant = variantIndex === image.variantIndex;
    return isSameView && isSameVariant;
  });
  if (!activeProductImage) {
    return currentImages[0];
  }
  return activeProductImage;
});
export const hiddenActiveProductImage = (state, props) => {
  const {
    variants
  } = state.product.product;
  const {
    view
  } = props;
  const {
    images
  } = variants[0];
  return _objectSpread({}, images[view][0], {
    isImageWithImprintArea: true
  });
};
export const currentImprintAreaSelector = createSelector(activeProductImageSelector, activeImage => {
  if (!activeImage || !activeImage.isImageWithImprintArea) {
    return null;
  }
  const {
    rectangle,
    rectangle: {
      width,
      height
    }
  } = activeImage;
  return _objectSpread({}, rectangle, {
    aspectRatio: width / height
  });
});
export const neighboringPreviewsSelector = createSelector(selectedVariantsImagesSelector, activeProductImageSelector, (allImages, currentImage) => {
  const currentIndex = allImages.findIndex(image => image.id === currentImage.id);
  const isLast = currentIndex === allImages.length - 1;
  const isFirst = currentIndex === 0;
  const next = isLast ? null : allImages[currentIndex + 1].id;
  const previous = isFirst ? null : allImages[currentIndex - 1].id;
  return {
    previous,
    next
  };
});
export const logoSizeSelector = createSelector(activeProductImageSelector, currentLogoSelector, (activeImage, currentLogo) => {
  if (!activeImage || !activeImage.isImageWithImprintArea || !activeImage.size) {
    return null;
  }
  const {
    size: {
      widthInch,
      heightInch
    }
  } = activeImage;
  if (!currentLogo) {
    return null;
  }
  const {
    rect: logoRect
  } = currentLogo;
  if (heightInch && widthInch && logoRect) {
    return {
      width: widthInch * logoRect.width / 100,
      height: heightInch * logoRect.height / 100
    };
  }
  return null;
});
export const productViewsSelector = createSelector(variantSelector, additionalPrintLocationsSelector, isFullDesignProductSelector, (variant, additionalPrintLocations, isFullDesignProduct) => {
  if (isFullDesignProduct) {
    return additionalPrintLocations.map(({
      id
    }) => id);
  }
  return Object.keys(variant.images).filter(imageLocation => variant.images[imageLocation] && variant.images[imageLocation].length && variant.images[imageLocation][0].url);
});
export const priceSelector = createSelector(orderItemSelector, orderItem => orderItem.price);
const quantityPerSizeEnabledSelector = createSelector(hasSizeSelector, sizeDependentPriceSelector, (hasSize, sizeDependent) => hasSize && !sizeDependent);
const sizeOptionsSelector = createSelector(productSelector, product => {
  var _product$productSize;
  return (_product$productSize = product.productSize) === null || _product$productSize === void 0 ? void 0 : _product$productSize.options;
});
export const selectedVariantsInStockLevelsSelector = createSelector(productVariantsSelector, selectedVariantsSelector, productInStockLevelsByVariantSkuSelector, sizeOptionsSelector, (variants, selectedVariants, stockLevelsByVariantSku, sizeSizeOptions) => {
  return selectedVariants.map(i => {
    const variant = variants[i];
    if (!variant) {
      return;
    }
    const {
      sizeSkus,
      isAlwaysInStockVariant
    } = variant;
    if (!sizeSkus || isAlwaysInStockVariant) {
      return;
    }
    return sizeSizeOptions.map(size => {
      const sku = sizeSkus[size];
      return stockLevelsByVariantSku[sku];
    });
  });
});
export const isQuantityAmountValid = createSelector(quantitySelector, productInStockLevelsByVariantSkuSelector, selectedVariantsDataSelector, sizeIndexSelector, hasSizeSelector, sizeOptionsSelector, productIsAlwaysInStockSelector, (totalQuantity, stockLevelsByVariantSku, selectedVariants, sizeIndex, hasSize, sizeSizeOptions, productIsAlwaysInStock) => {
  // @todo. auto generated. fix this error
  // @ts-ignore
  const mainQuantityValid = !getNotificationText({
    quantityForCurrentItem: totalQuantity,
    minQuantity: MIN_RELATED_AMOUNT,
    variants: selectedVariants,
    stockLevelsByVariantSku
  });
  const isEveryVariantValid = selectedVariants.every(variant => {
    const {
      quantities
    } = variant;
    if (hasSize) {
      return sizeSizeOptions.every((sizeLabel, index) => {
        var _variant$sizeSkus;
        // @todo. auto generated. fix this error
        // @ts-ignore
        return !getNotificationText({
          stockLevelsByVariantSku,
          productIsAlwaysInStock: productIsAlwaysInStock,
          quantityForCurrentItem: quantities[index],
          variants: [_objectSpread({}, variant, {
            productSku: (_variant$sizeSkus = variant.sizeSkus) === null || _variant$sizeSkus === void 0 ? void 0 : _variant$sizeSkus[sizeLabel]
          })]
        });
      });
    } else {
      const quantity = quantities && quantities[sizeIndex];
      // @todo. auto generated. fix this error
      // @ts-ignore
      return !getNotificationText({
        quantityForCurrentItem: quantity,
        variants: [variant],
        stockLevelsByVariantSku
      });
    }
  });
  return mainQuantityValid && isEveryVariantValid;
});
export const detailsSelector = createStructuredSelector({
  showPriceBreaks: showPriceBreaksSelector,
  sizeIndex: sizeIndexSelector,
  methodIndex: methodIndexSelector,
  splitOrderMode: splitOrderModeSelector,
  isFullDesignProduct: isFullDesignProductSelector,
  additionalPrintLocations: additionalPrintLocationsSelector,
  designNotes: designNotesSelector,
  showColorSection: showColorSectionSelector,
  variant: variantSelector,
  product: productSelector,
  sizes: sizeSelector,
  sizeLoaded: sizeLoadedSelector,
  discount: discountSelector,
  showEmbellishmentMethodSection: showEmbellishmentMethodSectionSelector,
  parentProductId: parentProductIdSelector,
  popupMode: popupModeSelector,
  isQuantityAmountValid
});
export const singleQuantityBlockSelector = createSelector([priceBreaksSelector, quantitiesSelector, minimumQuantitySelector, quantitySelector, sizeSelector, variantIndexSelector, quantityPerSizeEnabledSelector, sizeDependentPriceSelector, quantitiesReadySelector, variantSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, productOutOfStockThresholdSelector], ({
  showPriceBreaks
}, quantities, minQuant, totalQuantity, size, variantIndex, quantityPerSizeEnabled, sizeDependent, quantitiesReady, variant, stockLevelsByVariantSku, productIsAlwaysInStock, productOutOfStockThreshold) => {
  return {
    showPriceBreaks,
    minQuant,
    size,
    totalQuantity,
    quantities: quantities[variantIndex],
    variantIndex,
    quantityPerSizeEnabled,
    sizeDependent,
    stockLevelsByVariantSku,
    productIsAlwaysInStock,
    variant,
    outOfStockThreshold: productOutOfStockThreshold,
    errorText: quantitiesReady &&
    // @todo. auto generated. fix this error
    // @ts-ignore
    getNotificationText({
      quantityForCurrentItem: totalQuantity,
      minQuantity: MIN_RELATED_AMOUNT,
      variants: [variant],
      stockLevelsByVariantSku
    })
  };
});
export const multipleQuantityBlockSelector = createSelector([priceBreaksSelector, selectedVariantsDataSelector, minimumQuantitySelector, hasSizeSelector, sizeSelector, sizeIndexSelector, sizeLoadedSelector, quantitySelector, quantityPerSizeEnabledSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, productOutOfStockThresholdSelector], ({
  showPriceBreaks
}, variants, minQuant, hasSize, size, sizeIndex, sizeLoaded, totalQuantity, quantityPerSizeEnabled, stockLevelsByVariantSku, productIsAlwaysInStock, productOutOfStockThreshold) => {
  if (!sizeLoaded) {
    return {
      variants: []
    };
  }
  return {
    showPriceBreaks,
    variants,
    minQuant,
    size,
    sizeIndex,
    totalQuantity,
    quantityPerSizeEnabled,
    stockLevelsByVariantSku,
    productIsAlwaysInStock,
    outOfStockThreshold: productOutOfStockThreshold,
    // @todo. auto generated. fix this error
    // @ts-ignore
    errorText: getNotificationText({
      quantityForCurrentItem: totalQuantity,
      minQuantity: MIN_RELATED_AMOUNT,
      variants,
      stockLevelsByVariantSku,
      productIsAlwaysInStock
    })
  };
});
const variantsSelector = createSelector(productVariantsSelector, mainProductViewsSelector, (variants, productViews) => {
  return variants.filter(variant => Object.values(variant.images).filter(Boolean).length === productViews.length);
});
export const chooseColorSelector = createSelector(variantsSelector, splitOrderModeSelector, selectedVariantsSelector, variantDependentPriceSelector, productSelector, (productVariants, isSplitOrderModeOn, selectedVariants, variantDependentPrice, product) => {
  const variants = productVariants.map(variant => _objectSpread({}, variant, {
    isActive: selectedVariants.indexOf(variant.index) > -1
  }));
  const filteredVariants = variants.filter(variant => !variant.isHidden);
  return {
    variants,
    isSplitOrderModeOn,
    isSplitOrderModeAvailable: !variantDependentPrice && filteredVariants.length > 1,
    selectedVariants,
    splitColorsTooltip: isSplitOrderModeOn ? selectedSplitColorsTooltip : unselectedSplitColorsTooltip,
    product
  };
});

// merge images with imprint area with images without imprint area
export const mergeImages = (variant, imagesWithoutImprintArea, view, sizeIndex, variantIndex) => {
  const images = variant && variant.images || [];
  let imagesWithoutImprintAreaType = [];
  if (imagesWithoutImprintArea) {
    imagesWithoutImprintAreaType = imagesWithoutImprintArea.filter(image => image).map(image => _objectSpread({}, image, {
      isImageWithImprintArea: false,
      variantIndex
    }));
  }
  const imagesWithImprintAreaType = Object.keys(images).map(viewName => {
    if (!images[viewName]) {
      return null;
    }
    return _objectSpread({}, images[viewName][sizeIndex], {
      isImageWithImprintArea: true,
      variantIndex,
      id: viewName
    });
  });
  const allImages = [...imagesWithImprintAreaType, ...imagesWithoutImprintAreaType].filter(image => image !== null && image.url !== '');
  return {
    images: allImages
  };
};