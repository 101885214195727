import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { DEFAULT_PRODUCTS_PER_PAGE } from 'swag-common/constants/main-swag.constants';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { STATE_LOADING, STATE_ERROR, STATE_SUCCESS } from '../redux.constants';
import { CHANGE_IS_SHOW_MENU_STATE } from '../pages/pages.actions';
import { GET_CATEGORIES_STARTED, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL, GET_PRODUCTS_STARTED, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL, SORTING_CHANGED, RESET_PRODUCTS, CHANGE_ACTIVE_SUBCATEGORY_ID, CHANGE_APPLIED_CATEGORY_FILTER, CHANGE_CATEGORY, CHANGE_CHECKBOX_FILTER, SELECT_ALL, GET_FILTER_LOOKUPS_SUCCESS, REMOVE_FILTER_ITEM, APPLY_FILTER, RESET_FILTER, SET_IS_ALL_PRODUCTS_PAGE, SET_SELECTED_FILTER, SET_FILTER_VISIBILITY, CHANGE_PRICE_RANGE, CHANGE_RADIO_FILTER, OPEN_FILTER_POPUP, CLOSE_FILTER_POPUP, RESET_PAGE_NUMBER, SET_INCLUDE_SEARCHABLE_ONLY, CHANGE_IS_FOR_BOX, CHANGE_IS_FOR_INVENTORY, CHANGE_IS_FOR_SUPER_SPEED, GET_CATEGORIES_FAQ_DATA_SUCCESS, RESET_CATEGORIES_FAQ_DATA, SET_ANIMATION_VISIBILITY } from './product-listing.actions';
import { getUrlParamsObject, getInitialFilter, getInitialAttributeMap, isFilterPopupOpen, transformCategories, getInitialSorting } from './product-listing.utils';
import { currentParentCategorySelector } from './product-listing.selectors';
const initialUrl = getUrlParamsObject();
const initialFilter = getInitialFilter(initialUrl);
const initialState = {
  msg: undefined,
  err: undefined,
  snackbarIsOpen: undefined,
  categories: [],
  logoCategories: [],
  facets: {},
  attributesMap: getInitialAttributeMap(initialUrl),
  categoriesMap: {},
  currentCategoryName: null,
  subCategoryName: null,
  products: [],
  sorting: getInitialSorting({
    initialUrl,
    isProductListing: true
  }),
  perpage: DEFAULT_PRODUCTS_PER_PAGE,
  pagination: {
    page: 0,
    pageLoaded: null,
    lastPage: 1
  },
  total: 0,
  filterLookups: {
    osFilterOpened: true,
    production: {
      filterData: []
    },
    categoryId: {
      filterData: []
    }
  },
  activeSubcategoryId: null,
  selectedFilter: initialFilter,
  appliedFilter: initialFilter,
  isFilterOpened: isFilterPopupOpen(initialUrl),
  isAllProductsPage: false,
  categoriesFAQData: [],
  isLoading: false,
  getCategoriesState: STATE_LOADING,
  errored: false,
  isVisible: Object.keys(initialFilter).length > 0,
  withAnimation: true
};
export function productListingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INCLUDE_SEARCHABLE_ONLY:
      {
        let {
          selectedFilter: {
            includeSearchableOnly
          }
        } = state;
        if (!includeSearchableOnly) {
          includeSearchableOnly = [1];
        }
        return _objectSpread({}, state, {
          selectedFilter: _objectSpread({}, state.selectedFilter, {
            includeSearchableOnly
          })
        });
      }
    case GET_CATEGORIES_SUCCESS:
      {
        const {
          categories,
          categoriesMap,
          categoriesForFilter,
          logoCategories
        } = transformCategories(action.payload.categories);
        const {
          filterLookups,
          currentCategoryName
        } = state;
        const newState = _objectSpread({}, state, {
          categories,
          categoriesMap,
          logoCategories,
          getCategoriesState: STATE_SUCCESS,
          appliedFilter: _objectSpread({}, state.appliedFilter),
          selectedFilter: _objectSpread({}, state.selectedFilter),
          filterLookups: _objectSpread({}, filterLookups, {
            categoryId: {
              filterData: categoriesForFilter
            }
          })
        });
        newState.activeSubcategoryId = null;
        if (currentCategoryName && currentCategoryName !== ALL_SWAG_CATEGORY) {
          const originCategory = categoriesMap[currentCategoryName];
          let parentCategory;
          if (originCategory && originCategory.parent_id) {
            parentCategory = Object.values(categoriesMap).find(item => item._id === originCategory.parent_id);
          }
          const fullCategory = parentCategory || originCategory;
          if (fullCategory && (!newState.appliedFilter.categoryId || !newState.appliedFilter.categoryId.length)) {
            newState.appliedFilter.categoryId = parentCategory ? [parentCategory._id, originCategory._id] : [fullCategory._id];
            newState.selectedFilter.categoryId = parentCategory ? [parentCategory._id, originCategory._id] : [fullCategory._id];
            if (parentCategory) {
              newState.activeSubcategoryId = originCategory._id;
              newState.currentCategoryName = fullCategory.slug;
            }
          }
        }
        return newState;
      }
    case GET_CATEGORIES_FAIL:
      {
        const {
          err
        } = action;
        return _objectSpread({}, state, initialState, {
          getCategoriesState: STATE_ERROR,
          msg: JSON.stringify(err),
          err
        });
      }
    case GET_PRODUCTS_STARTED:
      {
        return _objectSpread({}, state, {
          isLoading: true
        });
      }
    case GET_CATEGORIES_STARTED:
      {
        return _objectSpread({}, state, {
          getCategoriesState: STATE_LOADING
        });
      }
    case SET_IS_ALL_PRODUCTS_PAGE:
      {
        const {
          isAllProductsPage,
          page,
          perpage
        } = action.payload;
        return _objectSpread({}, state, {
          perpage: perpage ? perpage : state.perpage,
          isAllProductsPage,
          pagination: _objectSpread({}, state.pagination, {
            page: page ? page : state.pagination.page
          })
        });
      }
    case RESET_PAGE_NUMBER:
      {
        return _objectSpread({}, state, {
          pagination: _objectSpread({}, state.pagination, {
            page: 0,
            pageLoaded: null
          })
        });
      }
    case GET_PRODUCTS_SUCCESS:
      {
        const {
          products,
          page,
          lastPage,
          total,
          facets
        } = action;
        const newProducts = _uniqBy(state.products.concat(products), '_id');
        return _objectSpread({}, state, {
          total,
          facets,
          isLoading: false,
          errored: false,
          products: newProducts,
          pagination: {
            page,
            lastPage,
            pageLoaded: page
          }
        });
      }
    case GET_PRODUCTS_FAIL:
      {
        const {
          err
        } = action;
        return _objectSpread({}, state, {
          snackbarIsOpen: true,
          isLoading: false,
          errored: true,
          msg: JSON.stringify(err),
          err
        });
      }
    case SORTING_CHANGED:
      {
        return _objectSpread({}, state, {
          sorting: action.sorting
        });
      }
    case RESET_PRODUCTS:
      {
        const {
          isAllProductsPage
        } = action;
        const {
          perpage,
          pagination: {
            page
          }
        } = state;
        return _objectSpread({}, state, {
          products: [],
          perpage: isAllProductsPage && perpage ? perpage : DEFAULT_PRODUCTS_PER_PAGE,
          pagination: {
            page: isAllProductsPage && page ? page - 1 : 0,
            lastPage: isAllProductsPage && page ? page : 1,
            pageLoaded: null
          }
        });
      }
    case CHANGE_CATEGORY:
      {
        const {
          category,
          subCategoryName
        } = action.payload;
        return _objectSpread({}, state, {
          currentCategoryName: category.slug,
          subCategoryName
        });
      }
    case CHANGE_ACTIVE_SUBCATEGORY_ID:
      {
        const {
          activeSubcategoryId
        } = action.payload;
        return _objectSpread({}, state, {
          activeSubcategoryId
        });
      }
    case CHANGE_APPLIED_CATEGORY_FILTER:
      {
        const {
          categoryIds,
          includeSearchableOnly
        } = action.payload;
        const url = getUrlParamsObject();
        const filtersFromUrl = getInitialFilter(url) || {};
        const selectedFilter = _objectSpread({}, filtersFromUrl, {
          categoryId: categoryIds
        });
        const appliedFilter = _objectSpread({}, filtersFromUrl, {
          categoryId: categoryIds
        });
        if (includeSearchableOnly && !filtersFromUrl[includeSearchableOnly]) {
          selectedFilter.includeSearchableOnly = [1];
          appliedFilter.includeSearchableOnly = [1];
        }
        return _objectSpread({}, state, {
          selectedFilter,
          appliedFilter,
          isVisible: Object.keys(filtersFromUrl).length > 0 || categoryIds.length > 1
        });
      }
    case SELECT_ALL:
      {
        const {
          filterId,
          values,
          isAttributes
        } = action.payload;
        const nextSelectedFilter = _objectSpread({}, state.selectedFilter, {
          [filterId]: values
        });
        if (!nextSelectedFilter[filterId].length) {
          delete nextSelectedFilter[filterId];
        }
        const attributesMap = isAttributes ? _objectSpread({}, state.attributesMap, {
          [filterId]: filterId
        }) : state.attributesMap;
        const currentParentCategory = currentParentCategorySelector({
          productListing: state
        });
        if (currentParentCategory && !nextSelectedFilter.categoryId && values.length) {
          nextSelectedFilter.categoryId = [currentParentCategory._id];
        }
        if (filterId === FilterIds.categoryId) {
          nextSelectedFilter[filterId] = [currentParentCategory._id].concat(nextSelectedFilter[filterId] || []);
        }
        return _objectSpread({}, state, {
          selectedFilter: nextSelectedFilter,
          attributesMap
        });
      }
    case CHANGE_CHECKBOX_FILTER:
      {
        const {
          filterId,
          filterPropertyValue,
          isAttributes,
          categoriesIdToRemove
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        const index = updatedFilter[filterId] && updatedFilter[filterId].indexOf(filterPropertyValue);
        const attributesMap = isAttributes ? _objectSpread({}, state.attributesMap, {
          [filterId]: filterId
        }) : state.attributesMap;
        if (categoriesIdToRemove) {
          updatedFilter.categoryId = updatedFilter.categoryId.filter(categoryId => !categoriesIdToRemove.includes(categoryId));
        }
        if (index !== -1 && index !== undefined) {
          updatedFilter[filterId].splice(index, 1);
          if (!updatedFilter[filterId].length) {
            delete updatedFilter[filterId];
            return _objectSpread({}, state, {
              selectedFilter: updatedFilter,
              attributesMap
            });
          }
          return _objectSpread({}, state, {
            attributesMap,
            selectedFilter: _objectSpread({}, updatedFilter, {
              [filterId]: updatedFilter[filterId]
            })
          });
        }
        if (!updatedFilter[filterId]) {
          updatedFilter[filterId] = [];
        }
        if (filterPropertyValue !== null) {
          updatedFilter[filterId].push(filterPropertyValue);
        }
        return _objectSpread({}, state, {
          attributesMap,
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_RADIO_FILTER:
      {
        const {
          filterId,
          filterPropertyValue
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        const index = updatedFilter[filterId] && updatedFilter[filterId].indexOf(filterPropertyValue);
        if (index !== -1 && index !== undefined) {
          delete updatedFilter[filterId];
          return _objectSpread({}, state, {
            selectedFilter: updatedFilter
          });
        }
        updatedFilter[filterId] = [filterPropertyValue];
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_FOR_BOX:
      {
        const {
          isBox
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isBox) {
          updatedFilter.isBox = true;
        } else {
          delete updatedFilter.isBox;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_FOR_INVENTORY:
      {
        const {
          isInventory
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isInventory) {
          updatedFilter.isInventory = true;
        } else {
          delete updatedFilter.isInventory;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case CHANGE_IS_FOR_SUPER_SPEED:
      {
        const {
          isSuperSpeed
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.selectedFilter);
        if (isSuperSpeed) {
          updatedFilter.isSuperSpeed = true;
          delete updatedFilter.productionTime;
          delete updatedFilter.isBox;
        } else {
          delete updatedFilter.isSuperSpeed;
        }
        return _objectSpread({}, state, {
          selectedFilter: updatedFilter
        });
      }
    case GET_FILTER_LOOKUPS_SUCCESS:
      {
        return _objectSpread({}, state, {
          isFilterOpened: true
        });
      }
    case APPLY_FILTER:
      {
        const appliedFilter = _cloneDeep(state.selectedFilter);
        if (!appliedFilter.production) {
          delete appliedFilter.production;
        }
        const {
            attributes
          } = appliedFilter,
          rest = _objectWithoutProperties(appliedFilter, ["attributes"]);
        return _objectSpread({}, state, {
          appliedFilter: _objectSpread({}, rest, attributes || {})
        });
      }
    case REMOVE_FILTER_ITEM:
      {
        const {
          filterId,
          filterIndex
        } = action.payload;
        const updatedFilter = _objectSpread({}, state.appliedFilter);
        if (!!filterId && filterIndex !== undefined) {
          updatedFilter[filterId].splice(filterIndex, 1);
        }
        if (!updatedFilter[filterId].length || typeof updatedFilter[filterId] === 'string' || filterId === FilterIds.price || filterId === FilterIds.isInventory || filterId === FilterIds.isBox || filterId === FilterIds.isSuperSpeed) {
          delete updatedFilter[filterId];
        }
        return _objectSpread({}, state, {
          selectedFilter: _cloneDeep(updatedFilter),
          appliedFilter: updatedFilter,
          isVisible: Object.values(updatedFilter).length === 0 ? false : state.isVisible
        });
      }
    case RESET_FILTER:
      {
        const {
          resetCategory
        } = action.payload;
        const selectedFilter = {};
        if (!resetCategory) {
          const currentParentCategory = currentParentCategorySelector({
            productListing: state
          });
          selectedFilter.categoryId = [currentParentCategory._id];
        }
        return _objectSpread({}, state, {
          selectedFilter,
          appliedFilter: selectedFilter,
          activeSubcategoryId: null,
          isVisible: false
        });
      }
    case SET_SELECTED_FILTER:
      {
        const {
          appliedFilter,
          selectedFilter
        } = state;
        const {
          filter: {
            categoryId
          }
        } = action.payload;
        if (categoryId) {
          appliedFilter.categoryId = categoryId;
          selectedFilter.categoryId = categoryId;
        }
        return _objectSpread({}, state, {
          appliedFilter,
          selectedFilter
        });
      }
    case SET_FILTER_VISIBILITY:
      {
        const {
          isVisible
        } = action.payload;
        return _objectSpread({}, state, {
          isVisible
        });
      }
    case SET_ANIMATION_VISIBILITY:
      {
        const {
          withAnimation
        } = action.payload;
        return _objectSpread({}, state, {
          withAnimation
        });
      }
    case CHANGE_PRICE_RANGE:
      {
        const {
          range
        } = action.payload;
        return _objectSpread({}, state, {
          selectedFilter: _objectSpread({}, state.selectedFilter, {
            price: range
          }),
          appliedFilter: _objectSpread({}, state.selectedFilter, {
            price: range
          })
        });
      }
    case OPEN_FILTER_POPUP:
      {
        return _objectSpread({}, state, {
          isFilterOpened: true
        });
      }
    case CHANGE_IS_SHOW_MENU_STATE:
      {
        const {
          isShown
        } = action.payload;
        return isShown ? _objectSpread({}, state, {
          isFilterOpened: false
        }) : state;
      }
    case CLOSE_FILTER_POPUP:
      {
        return _objectSpread({}, state, {
          isFilterOpened: false
        });
      }
    case GET_CATEGORIES_FAQ_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          categoriesFAQData: action.payload
        });
      }
    case RESET_CATEGORIES_FAQ_DATA:
      {
        return _objectSpread({}, state, {
          categoriesFAQData: initialState.categoriesFAQData
        });
      }
    default:
      {
        return state;
      }
  }
}