export const LINK_DASHBOARD_PM = '/dashboard/page-management';
export const LINK_DASHBOARD_PM_BROWSE_PRODUCTS = '/dashboard/page-management/browse-products';
export const LINK_DASHBOARD_PM_FAQ = '/dashboard/page-management/faq';
export const LINK_DASHBOARD_PM_FAQ_SWAG = '/dashboard/page-management/faq/swag';
export const LINK_DASHBOARD_PM_FAQ_SWAG_SPACE = '/dashboard/page-management/faq/swag-space';
export const LINK_DASHBOARD_PM_FAQ_CUSTOM_INK = '/dashboard/page-management/faq/custom-ink';
export const LINK_DASHBOARD_PM_PAST_WORKS = '/dashboard/page-management/past-works';
export const LINK_DASHBOARD_PRODUCTS = '/dashboard/products';
export const LINK_DASHBOARD_COLLECTIONS = '/dashboard/collections';
export const LINK_DASHBOARD_SIZE_EDITOR = '/dashboard/sizes-editor';
export const LINK_DASHBOARD_CATEGORY_TYPES = '/dashboard/category-types';
export const LINK_DASHBOARD_SUPPLIERS = '/dashboard/suppliers';
export const LINK_DASHBOARD_PROD_REF = '/dashboard/product-references';
export const LINK_DASHBOARD_PROD_NOTIF = '/dashboard/product-notifications';
export const LINK_DASHBOARD_ITEMS = '/dashboard/items';
export const LINK_PRODUCT_SOURCING_ORDERS = '/dashboard/product-sourcing/orders';
export const LINK_PRODUCT_SOURCING_ORDER_OVERVIEW = '/dashboard/product-sourcing/orders/overview';
export const LINK_DASHBOARD_ORDER_OVERVIEW = '/dashboard/orders/overview';
export const LINK_DASHBOARD_ORDER_ABANDONED = '/dashboard/orders/abandoned';
export const LINK_DASHBOARD_ORDER_BUILD_A_CART = '/dashboard/orders/build-a-cart';
export const LINK_DASHBOARD_PROMOCODES = '/dashboard/promocodes';
export const LINK_DASHBOARD_PROMO_EMAILS = '/dashboard/promo-emails';
export const LINK_DASHBOARD_COMP_ORDERS_MANAGEMENT = '/dashboard/company-orders-management';
export const LINK_DASHBOARD_EMAIL_SUBMISSIONS = '/dashboard/email-submissions';
export const LINK_DASHBOARD_USERS = '/dashboard/users';
export const LINK_DASHBOARD_GAM = '/dashboard/gamification';
export const LINK_DASHBOARD_GAM_SETTINGS = '/dashboard/gamification/settings';
export const LINK_DASHBOARD_COMPANIES = '/dashboard/gamification/companies';
export const LINK_DASHBOARD_SENDOSO_SETTINGS = '/dashboard/sendoso-settings';
export const LINK_DASHBOARD_POSTAL_SETTINGS = '/dashboard/postal-settings';
export const LINK_DASHBOARD_INV_PRODUCTS = '/dashboard/inventory-products';
export const LINK_DASHBOARD_INVENTORIES = '/dashboard/inventories';
export const LINK_DASHBOARD_INVENTORIES_INVOICES = '/dashboard/invoices';
export const LINK_DASHBOARD_DISTRIBUTIONS = '/dashboard/distributions';
export const LINK_DASHBOARD_CREDITS = '/dashboard/credits';
export const LINK_DASHBOARD_GOODIE_GIVEAWAY_SETTINGS = '/dashboard/goodie-giveaway-settings';
export const LINK_GOODIE_GIVEAWAY_CATALOG = 'catalog';
export const LINK_GOODIE_GIVEAWAY_CATALOG_FULL = `${LINK_DASHBOARD_GOODIE_GIVEAWAY_SETTINGS}/${LINK_GOODIE_GIVEAWAY_CATALOG}`;
export const LINK_GOODIE_GIVEAWAY_CATEGORIES = 'categories';
export const LINK_DASHBOARD_SHIPPING_SETTINGS = '/dashboard/shipping-settings';
export const LINK_DASHBOARD_RESELLERS = '/dashboard/reseller/resellers';
export const LINK_DASHBOARD_RESELLER_APPLICATIONS = '/dashboard/reseller/applications';
export const LINK_DASHBOARD_TAX_EXEMPTIONS = '/dashboard/reseller/tax-exemptions';
export const LINK_DASHBOARD_WITHDRAWAL_REQUESTS = '/dashboard/reseller/withdrawal-requests';
export const LINK_DASHBOARD_RESELLER_CERTIFICATES = '/dashboard/reseller/reseller-certificates';
export const LINK_DASHBOARD_SHOPIFY_STORES = '/dashboard/shopify-stores';
export const LINK_DASHBOARD_SHOPIFY_STORES_SWAG_APP = '/dashboard/shopify-stores/swag-app';