import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
export const checkIsProductAvailableForBundleCreation = product => {
  if (product.psProduct && !product.psProduct.isAvailableForPackagedBundle) {
    return false;
  }
  if (isBox(product.swagProductId) && product.variants.some(v => {
    var _v$boxContent;
    return v === null || v === void 0 ? void 0 : (_v$boxContent = v.boxContent) === null || _v$boxContent === void 0 ? void 0 : _v$boxContent.length;
  })) {
    return false;
  }
  return !isBundle(product.variants[0]);
};