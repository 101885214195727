import _omitBy2 from "lodash/omitBy";
import _flow2 from "lodash/flow";
import _omit2 from "lodash/omit";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import qs from 'querystring';
import { replace } from 'react-router-redux';
import { SET_SELECTED_FILTER, OPEN_FILTER_POPUP, CLOSE_FILTER_POPUP } from '../redux/product-listing/product-listing.actions';
import { APPLY_FILTER as SET_SELECTED_SEARCH, SEARCH_SUCCESS } from '../redux/search-results/search-results.actions';
import { SET_PRODUCT_ID_TO_URL } from '../redux/search-products/search-products.actions';
import { NON_FILTER_PARAMS, FILTER_PREFIX, addFilterPrefix } from '../redux/product-listing/product-listing.utils';
const ONE_LINE_PAGE_OFFSET = 500;

// Helpers
const getNextFilters = (parsedSearchQuery, selectedFilters, nextSelectedFilters) => {
  const mappedFilters = {};
  const pathsToOmit = ['page'];
  Object.keys(_omit2(selectedFilters, pathsToOmit)).forEach(key => {
    mappedFilters[addFilterPrefix(key)] = (nextSelectedFilters || selectedFilters)[key];
  });
  const nonFilterQueryParams = _flow2([ctx => _omit2(ctx, NON_FILTER_PARAMS), ctx => _omitBy2(ctx, (value, key) => key.includes(FILTER_PREFIX))])(parsedSearchQuery);
  return _objectSpread({}, mappedFilters, nonFilterQueryParams);
};
export default (({
  getState,
  dispatch
}) => next => action => {
  const location = getState().routing.location || window.location;
  const parsedSearchQuery = qs.parse(location.search.replace('?', ''));
  switch (action.type) {
    case SET_SELECTED_FILTER:
      {
        const location = getState().routing.location || window.location;
        const {
          sorting,
          isFilterOpened,
          selectedFilter
        } = getState().productListing;
        const nextFilters = getNextFilters(parsedSearchQuery, selectedFilter, action.payload.filter);
        dispatch(replace(`${location.pathname}?${qs.stringify(_objectSpread({}, nextFilters, {
          sorting,
          open: isFilterOpened || parsedSearchQuery.open || false
        }))}${location.hash}`));
        break;
      }
    case SET_PRODUCT_ID_TO_URL:
      {
        const {
          pathname,
          search
        } = location;
        const {
          pageYOffset
        } = window;
        const hash = action.payload.id && pageYOffset > ONE_LINE_PAGE_OFFSET ? `#${action.payload.id}` : '';
        dispatch(replace(`${pathname}${search}${hash}`));
        break;
      }
    case SET_SELECTED_SEARCH:
    case SEARCH_SUCCESS:
      {
        const {
          query
        } = parsedSearchQuery;
        const {
          selectedFilter,
          sorting
        } = getState().searchResults;
        const nextFilters = getNextFilters(parsedSearchQuery, selectedFilter);
        dispatch(replace({
          pathname: location.pathname,
          query: _objectSpread({}, nextFilters, {
            sorting,
            query
          })
        }));
        break;
      }
    case OPEN_FILTER_POPUP:
      {
        dispatch(replace({
          pathname: location.pathname,
          query: _objectSpread({}, parsedSearchQuery, {
            open: true
          })
        }));
        break;
      }
    case CLOSE_FILTER_POPUP:
      {
        dispatch(replace({
          pathname: location.pathname,
          query: _objectSpread({}, parsedSearchQuery, {
            open: false
          })
        }));
        break;
      }
    default:
  }
  return next(action);
});