function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isItemsInTheSameBox } from 'swag-common/utils/order/item-is-box.util';
import { calculateUpsellQuantity } from '../calculate-upsell-quantity';
export function getNextPriceBreakForItemInBox({
  item,
  product,
  sharedItems,
  percentToNextPriceBreak,
  nextPriceBreak: propsNextPriceBreak,
  selectedVariants,
  boxQuantity
}) {
  const {
    current: associatedProductsQuantity,
    initial: initialAssociatedProductsQuantity,
    sameBoxCurrent: sameBoxCurrentQuantity,
    sameBoxInitial: sameBoxInitialQuantity,
    quantityPerBox
  } = sharedItems.reduce((acc, relatedItem) => {
    if (String(relatedItem._id) === String(item === null || item === void 0 ? void 0 : item._id) || isItemsInTheSameBox({
      currentItem: item,
      item: relatedItem
    })) {
      return _objectSpread({}, acc, {
        sameBoxCurrent: acc.sameBoxCurrent + boxQuantity * (relatedItem.quantityPerBox || 1),
        sameBoxInitial: acc.sameBoxInitial + (relatedItem.initialQuantity || relatedItem.quantity),
        quantityPerBox: acc.quantityPerBox + (relatedItem.quantityPerBox || 1)
      });
    }
    return _objectSpread({}, acc, {
      current: acc.current + relatedItem.quantity,
      initial: acc.initial + (relatedItem.initialQuantity || relatedItem.quantity)
    });
  }, {
    initial: 0,
    current: 0,
    sameBoxCurrent: 0,
    sameBoxInitial: 0,
    quantityPerBox: 0
  });
  const totalQuantity = sameBoxCurrentQuantity + associatedProductsQuantity;
  const {
    nextPriceBreak,
    isWithinRange,
    priceBreaks
  } = calculateUpsellQuantity({
    product,
    quantity: totalQuantity,
    selectedVariants,
    percentToNextPriceBreak
  });
  const initialTotalQuantity = sameBoxInitialQuantity + initialAssociatedProductsQuantity;
  const {
    nextPriceBreak: initialPriceBreak
  } = calculateUpsellQuantity({
    product,
    quantity: initialTotalQuantity,
    selectedVariants,
    percentToNextPriceBreak
  });
  const propsNextPriceBreakTotal = propsNextPriceBreak && propsNextPriceBreak + associatedProductsQuantity;
  let nextPriceBreakToUse = nextPriceBreak;
  if (propsNextPriceBreakTotal) {
    nextPriceBreakToUse = propsNextPriceBreakTotal;
    // if the closest priceBreak is lower then initial, should use initial
  } else if (initialPriceBreak && nextPriceBreak && initialPriceBreak > nextPriceBreak) {
    nextPriceBreakToUse = initialPriceBreak;
  }
  const initialQuantity = initialPriceBreak && Math.ceil((initialPriceBreak - initialAssociatedProductsQuantity) / (quantityPerBox || 1));
  const newQuantity = nextPriceBreakToUse && Math.ceil((nextPriceBreakToUse - associatedProductsQuantity) / (quantityPerBox || 1));
  return {
    nextPriceBreak: nextPriceBreakToUse,
    newQuantity,
    initialQuantity,
    isWithinRange,
    priceBreaks
  };
}