import _cloneDeep from "lodash/cloneDeep";
import { Price } from 'swag-client-common/utils/price';
import { checkIsCustomInkTenant } from 'swag-common/utils/check-is-custom-ink-tenant.util';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { discardMultiplierToProduct } from 'swag-common/utils/tenant-price-settings';
export function getItemPriceFromProduct({
  orderItem,
  product,
  blendedQuantity,
  screenPrice,
  totalQuantityForSharedItems,
  discardMultiplier
}) {
  const {
    tenant,
    storeSettings
  } = window.swag;
  if (checkIsCustomInkTenant(tenant)) {
    const price = Price.getPrice({
      orderItem,
      product,
      blendedQuantity,
      screenPrice,
      totalQuantityForSharedItems
    });
    return price;
  }
  const multiplier = getItemPriceMultiplier(orderItem) || storeSettings.storePricesMultiplier;
  let productToUse = product;
  if (discardMultiplier) {
    productToUse = discardMultiplierToProduct(_cloneDeep(product), multiplier);
  }
  const price = Price.getPrice({
    orderItem,
    product: productToUse,
    blendedQuantity,
    screenPrice,
    totalQuantityForSharedItems,
    multiplier
  });
  return price;
}