import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isDisabledSelector, isPdfSelector, isRequestingChangesSelector, itemIdSelector, mockupSelector, orderIdSelector, requestChangesStateSelector, stateSelector } from '../../../../../redux/mockup-manager/mockup-manages.selectors';
import { editMockupChangeRequest, approveMockup, requestMockupChanges, requestChanges } from '../../../../../redux/mockup-manager/mockup-manager.actions';
import { ApproveMockup as ApproveMockupComponent } from './approve-mockup';
const mapStateToProps = createStructuredSelector({
  mockup: mockupSelector,
  itemId: itemIdSelector,
  orderId: orderIdSelector,
  requestChangesState: requestChangesStateSelector,
  state: stateSelector,
  isRequestingChanges: isRequestingChangesSelector,
  isPdf: isPdfSelector,
  isDisabled: isDisabledSelector
});
const mapDispatchToProps = dispatch => ({
  onRequestChanges: bool => () => {
    dispatch(requestChanges(bool));
  },
  onEditChangeRequest: event => {
    dispatch(editMockupChangeRequest(event.target.value));
  },
  onRequestChangesSubmit: ({
    itemId,
    orderId,
    mockup,
    status
  }) => () => {
    dispatch(requestMockupChanges({
      itemId,
      orderId,
      mockup,
      status
    }));
  },
  onApproveSubmit: ({
    itemId,
    orderId,
    mockup,
    status
  }) => () => {
    dispatch(approveMockup({
      itemId,
      orderId,
      mockup,
      status
    }));
  }
});
export const ApproveMockup = connect(mapStateToProps, mapDispatchToProps)(ApproveMockupComponent);