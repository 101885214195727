import { getNextPriceBreakForStandaloneCartItem } from './get-next-price-break-for-standalon-cart-item.util';
import { getNextPriceBreakForItemInBox } from './get-next-price-break-for-item-in-box.util';
export function getNextPriceBreakForCartItem({
  item,
  product,
  sharedItems,
  boxQuantity,
  quantity,
  percentToNextPriceBreak,
  nextPriceBreak: propsNextPriceBreak,
  selectedVariants,
  shouldApplyInitialPB = false
}) {
  return Boolean(item === null || item === void 0 ? void 0 : item.boxItemsId) && boxQuantity ? getNextPriceBreakForItemInBox({
    item,
    product,
    sharedItems,
    percentToNextPriceBreak,
    nextPriceBreak: propsNextPriceBreak,
    selectedVariants,
    boxQuantity
  }) : getNextPriceBreakForStandaloneCartItem({
    item,
    product,
    sharedItems,
    percentToNextPriceBreak,
    quantity,
    nextPriceBreak: propsNextPriceBreak,
    selectedVariants,
    shouldApplyInitialPB
  });
}