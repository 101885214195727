function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mergePriceBreaks } from './calculate-upsell-quantity/merge-price-breaks';
import { getUpsellQuantity } from './calculate-upsell-quantity/get-upsell-quantity';
import { calculateInStockAmountForAllVariants } from './calculate-upsell-quantity/calculate-in-stock-amount-for-all-variants';
import { calculatePriceBreaksWillBeShownFrom } from './calculate-upsell-quantity/calculate-price-breaks-will-be-shown-from';
export const calculateUpsellQuantity = ({
  product,
  quantity: userQuantity,
  selectedVariants,
  percentToNextPriceBreak
}) => {
  const {
    quantities,
    quantIncrement,
    printRunQuantities,
    supplierInventoryLevels,
    isAlwaysInStock
  } = product;
  const inStock = calculateInStockAmountForAllVariants({
    selectedVariants,
    supplierInventoryLevels,
    productIsAlwaysInStock: isAlwaysInStock
  });
  const mergedPriceBreaks = mergePriceBreaks({
    printRunQuantities,
    basePriceQuantities: quantities
  });
  const priceBreaksWillBeShownFrom = calculatePriceBreaksWillBeShownFrom({
    inStock,
    quantIncrement,
    priceBreaks: mergedPriceBreaks,
    percentToNextPriceBreak
  });
  const upsellQuantity = getUpsellQuantity({
    priceBreaks: mergedPriceBreaks,
    priceBreaksWillBeShownFrom,
    userQuantity
  });
  return _objectSpread({}, upsellQuantity, {
    priceBreaks: mergedPriceBreaks
  });
};